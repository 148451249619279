import { useState } from "react";
import { Routes, Route, Link } from "react-router-dom";
import "./App.css";
import MainMint from "./MainMint.js";
import NavBar from "./NavBar";
import Home from './pages/Home';
import MintPage from "./pages/MintPage";

//import abi from "./utils/MiddayStorm.json";

function App() {
  const [accounts, setAccounts] = useState([]);
  return (
    // <div className="overlay">
    // <div className="App">
    // <NavBar accounts={accounts} setAccounts={setAccounts}/>
    // <MainMint accounts={accounts} setAccounts={setAccounts}/>
    // </div>
    // <div className="background"></div>
    // </div>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/start-mint" element={<MintPage />} />
    </Routes>
   );
}

export default App

  // const [currentAccount, setCurrentAccount] = useState("");
  // // const contractAddress = "0x";

  // //const contractABI = abi.abi;

  // const checkIfWalletIsConnected = async () => {
  //   try {
  //     const { ethereum } = window;

  //     if (!ethereum) {
  //       console.log("Make sure you have metamask!");
  //       return;
  //     } else {
  //       console.log("We have the ethereum object", ethereum);
  //     }

  //     const accounts = await ethereum.request({ method: "eth_accounts" });

  //     if (accounts.length !== 0) {
  //       const account = accounts[0];
  //       console.log("Found an authorized account:", account);
  //       setCurrentAccount(account);
  //     } else {
  //       console.log("No authorized account found")
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  // const connectWallet = async () => {
  //   try {
  //     const { ethereum } = window;

  //     if (!ethereum) {
  //       alert("Get MetaMask!");
  //       return;
  //     }

  //     const accounts = await ethereum.request({ method: "eth_requestAccounts" });

  //     console.log("Connected", accounts[0]);
  //     setCurrentAccount(accounts[0]);
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }


  // useEffect(() => {
  //   checkIfWalletIsConnected();
  // }, [])

  // return (
  //   <div className="mainContainer">
  //     <div className="dataContainer">
  //       <div className="header">
  //       Ukiyoe
  //       </div>

  //       {/*
  //       * If there is no currentAccount render this button
  //       */}
  //       {/* {!currentAccount && (
  //         <button className="waveButton" onClick={connectWallet}>
  //           Connect Wallet/Mint
  //         </button>
  //       )} */}
  //         <button className="connectButton" onClick={connectWallet}>
  //           Connect Wallet
  //         </button>
  //         <button className="mintButton" onClick={connectWallet}>
  //           Mint
  //         </button>         
  //     </div>
  //   </div>
  // );