import React from "react";
import MainMint from "../MainMint";

const MintPage = () => {

    return (
        <div className="mintpage-wrapper">
            mint page 123
        </div>
    )
}

export default MintPage;