import React from "react";

class Home extends React.Component {

    componentDidMount () {
        const script = document.createElement("script");
    
        script.src = "assets/js/main.js";
        script.async = true;
    
        document.body.appendChild(script);
    }

    render() {
        return (
            <div>
                <div className="wrap">
                    <header>
                        <a href="/" className="logo">
                            <img src="assets/images/logo.png" alt="" />
                            <h1>UKIYOE</h1>
                        </a>
                    
                        <nav className="nav">
                            <ul className="menu-list font-face-rror">
                                <li><a href="#about">About</a></li>
                                <li><a href="#roadmap">Manifesto</a></li>
                                <li><a href="#team">Team</a></li>
                                {/* <li><a href="#faq">FAQ</a></li> */}
                            </ul>
                            <div className="social">
                                <a href="/" className="discord"><img src="assets/images/discord.png" alt="" /></a>
                                <a href="https://twitter.com/UkiyoeOfficial" className="twitter" target="_blank" rel="noopener noreferrer"><img src="assets/images/twitter.png" alt="" /></a>
                                <a href="/" className="boat"><img src="assets/images/boat.png" alt="" /></a>
                            </div>
                        </nav>   
                        <button className="mobi-bar">
                            <span></span>
                            <span></span>
                            <span></span>
                        </button>  
                    </header>

                    <section className="main-sec"></section>

                    <section className="about-sec" id="about">
                        <div className="title rellax" data-rellax-speed="2" data-rellax-xs-speed="1"></div>
                        <div className="txt rellax font-face-rror" data-rellax-speed="1">
                            <h3><img src="assets/images/text_icon.png" alt="" />UkiyoE</h3>
                            <p>Ukiyo-e is an ancient Japanese art genre that illustrates reality via a traditional form of painting. The beautifully written Japanese word 浮世絵 (ukiyo-e), taken together as a whole carries the literal meaning of a “picture of the floating world”.  Apart from the art genre, which is the heart and soul of the project, the NFT collection also drew inspiration from the historic masterpiece “Gojo Bridge”, from the hand of the great master Tsukioka Yoshitoshi; who was at the forefront of bridging the transition between traditional Japanese art and modern printing.</p>
                            <p>We resonated deeply with Yoshitoshi and his vision. As inspiration flew and actions taken, Project Ukiyo-e is born. As a start, we wish to pay a tribute to the spirit of the ancient master, by recreating the Gojo Bridge with a twist, celebrating the success of NFT as the new form of digital collectible. Moreover, unlike the fight on Gojo Bridge, our fight does not end here. This NFT will serve not only as a digital collectible. We are a team consists a bunch of talents and resources that aims to unlock the potential of everything that Web3 has to offer.</p>
                            <p>This is project Ukiyo-E, remember us as a Web 3 brand. Did you hear the war drum roaring? We’re just getting started.</p>
                        </div>
                        <div className="wave-img">
                            <img src="assets/images/wave.png" alt="" />
                        </div>
                    </section>
                
                    <section className="roadmap-sec" id="roadmap">
                        <div className="title"></div>
                        <div className="roadmap">
                            <div className="line"></div>
                            <ul className="timelist font-face-rror">
                                <li className="now">
                                    <h4 className="time">Manifesto</h4>
                                    <ul className="work">
                                        <li>In our eyes, NFT is a new form of fund raising channel and hence, our first release would be synonymous to a startup.</li>
                                        <li>In this chaotic NFT space, pinning every milestone on a dated roadmap is unrealistic and has proved difficult in managing expectation.</li>
                                        <li>In place of a dated roadmap, what we offer is a promise to keep you informed with a coherent and consistent voice on our mission and value.</li>
                                    </ul>
                                </li>
                                <li>
                                    <h4 className="time">Our promises & high level direction to our NFT holders</h4>
                                    <ul className="work">
                                        <li>Ukiyoe as a launchpad for commercial artist, bridging Web2 and IRL commerce into Web3.</li>
                                        <li>NFT acting as a direct access to profit sharing or revenue model ownership, benefits generated under this brand will be given back to the community.</li>
                                        <li>Original NFT holders will get priority access to all future NFT airdrop or at a discounted rate.</li>
                                        <li>Future drops will be considered as fund raising rounds and will be carefully examined and communicated with the community prior execution to avoid share dilution.</li>
                                        <li>Ukiyoe is the first step to become a household Web3 commercial brand; Gen 1 banner will serve as a token of ownership and proof of early support.</li>
                                    </ul>
                                </li>
                                {/* <li className="now">
                                    <h4 className="time">Setting up the foundation - 2022 Q2</h4>
                                    <ul className="work">
                                        <li>Establishing the foundations by launching 6666 genesis ukiyoe banner art as a community token. Our vision is to discover talented artists and become a project launchpad.</li>
                                        <li>Recreate the iconic ukiyoe drawings featuring 10+ well-known NFT avatars as samurais.</li>
                                        <li>Over 100 random traits and surprises to be revealed.</li>
                                        <li>Support and assist talented artists from around the world to showcase their talent in web3.</li>
                                        <li>Build and grow community, web 3 branding and cross project collab.</li>
                                        <li>HD PC & mobile wallpapers available exclusively for NFT holders.</li>
                                    </ul>
                                </li>
                                <li>
                                    <h4 className="time">banner v2 - 2022 Q3</h4>
                                    <ul className="work">
                                        <li>Banner collection Vol.2… a brand new story unfolds.</li>
                                        <li>Community can vote on artists, traits, and an art style that they want.</li>
                                        <li>Genesis holders are entitled to WL for Vol.2 collection.</li>
                                    </ul>
                                </li>
                                <li>
                                    <h4 className="time">PFP project - 2022 Q3 - Q4</h4>
                                    <ul className="work">
                                        <li>Collect your favorite <b>katana</b>, choose to be a <b>samurai or ronin</b>, decide your own appearance. Build your own <b>Senshi</b><i>(戦士, warrior)</i></li>
                                        <li>Interchangeable PFP collectibles will drop in three different collections.</li>
                                        <li>Updated interactive platform for the PFP collection.</li>
                                        <li>Total of 3 components (collections) to complete a full warrior.</li>
                                        <li>All components will be <b>free mint</b> for genesis banner holders.</li>
                                        <li>Your choice to upload all 3 to exchange for a samurai PFP, or keep as separate parts and trade freely on secondary market.</li>
                                    </ul>
                                </li> */}
                            </ul>
                        </div>
                        <img className="warrior" src="assets/images/warrior.png" alt="" />        
                    </section>

                    <section className="tree-sec"></section> 

                    <div className="bottom-wrap">
                        <section className="team-sec" id="team">    
                            
                            <div className="title"></div>
                            <ul className="team-list font-face-rror">
                                <li>
                                    <div className="name">TL</div>
                                    <h4 className="pos">Project Lead</h4>
                                    <span>Ex management consultant, series entrepreneur. Currently working in a web3 analytic team, BUDILING!</span>
                                </li>
                                <li>
                                    <div className="name">KX</div>
                                    <h4 className="pos">Dev do something</h4>
                                    <span>Tech lead & senior developer. Specialize in artificial intelligence/machine learning, algorithm design, blockchain and smart contract development. Also a NFT whale on the side.</span>
                                </li>
                                <li>
                                    <div className="name">IS</div>
                                    <h4 className="pos">Engineer</h4>
                                    <span>Full-stack software engineer, PM, series entrepreneur in e-commerce. Does everything and collect NFTs.</span>
                                </li>
                                <li>
                                    <div className="name">JW</div>
                                    <h4 className="pos">Community</h4>
                                    <span>Formerly a professional stock trader and coach, business owner, decided to go full degen in crypto.</span>
                                </li>
                                <li>
                                    <div className="name">SC</div>
                                    <h4 className="pos">Marketing</h4>
                                    <span>Marketing specialist, extensive PM & Public Relation experience in large US medical firm.</span>
                                </li>
                                <li>
                                    <div className="name">YT</div>
                                    <h4 className="pos">Design</h4>
                                    <span>Ex-psychologist with extensive business development experience, current working as a QA in growing tech sector.</span>
                                </li>
                                <li>
                                    <div className="name">Sean</div>
                                    <h4 className="pos">Artist</h4>
                                    <span>15+ years of experience in the creative industry. Professional corporate designer and creative designer.</span>
                                </li>
                            </ul>
                        </section>

                        <section className="faq-sec" id="faq">
                            {/* <div className="title"></div>
                            <div className="faq-box">
                                <div className="top"></div>
                                <div className="center">
                                    <ul className="cont-list font-face-rror">
                                        <li>
                                            <h4>What is the total supply and mint price?</h4>
                                            <p>6666, TBA</p>
                                        </li>
                                        <li>
                                            <h4>What type of contract are we using? </h4>
                                            <p>ERC721A</p>
                                        </li>
                                        <li>
                                            <h4>How do we differentiate from other banners?</h4>
                                            <p>Art is cool, right? But what if we tell you that this is only the beginning? We want to bring together the people that appreciate the art and explore what’s possible in the ukiyoe universe.</p>
                                        </li>
                                        <li>
                                            <h4>Any utility for the NFTs?</h4>
                                            <p>Our NFTs will serve as an access pass to all future drops from us, holding one grant holders free mints or guaranteed mint spots in later projects. Also a beautiful banner art for you to showcase on Twitter or Discord profile.</p>
                                        </li>
                                        <li>
                                            <h4>How many artist behind this project?</h4>
                                            <p>One very good artist with many years of experience in creative design.</p>
                                        </li>
                                    </ul>
                                </div>
                                <div className="bottom"></div>
                            </div> */}
                        </section>
                    </div>  
                </div>
            </div>
        );
    }
}

export default Home;